import React, { FunctionComponent } from 'react'
// @ts-ignore
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { Trans } from '@lingui/macro'
import ListSocials from 'components/atoms/ListSocials/ListSocials'
import { ButtonRed, PrimaryTitle } from 'styles/Global'
import {
  BlocContactBg,
  BlocContactBirds,
  BlocContactContainer,
  BlocContactImg,
} from './BlocContact.style'
import Avatar from 'assets/images/home/avatar.png'
import Birds1 from 'assets/images/studio/birds-1.png'
import Birds2 from 'assets/images/studio/birds-2.png'

interface Props {
  background?: string
  withBirds?: boolean
}

const BlocContact: FunctionComponent<Props> = ({ background, withBirds }) => {
  return (
    <BlocContactContainer background={background}>
      <BlocContactBg>
        <PrimaryTitle>
          <Trans id="bloc.contact.title">
            Vous avez un projet à développer ?
          </Trans>
        </PrimaryTitle>
        <ButtonRed as={Link} to="/contact">
          <Trans id="bloc.contact.button">Contactez-nous</Trans>
        </ButtonRed>
        <ListSocials />
        {withBirds ? (
          <BlocContactBirds>
            <img src={Birds1} alt="birds image" />
            <img src={Birds2} alt="birds image" />
          </BlocContactBirds>
        ) : (
          <BlocContactImg src={Avatar} alt="" />
        )}
      </BlocContactBg>
    </BlocContactContainer>
  )
}

export default BlocContact
