import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { Container, MainText, PrimaryTitle, SmallText } from 'styles/Global'
import { StudioHero, StudioHeroScroll } from 'styles/pages/studio.style'
import BlocFooter from 'assets/images/studio/blocFooter.png'
import { IconsName } from 'assets/svg/Icons.enum'
import { fetchStudioImg } from 'hooks/fetch-studio-img'

import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Icons from 'components/atoms/Icons/Icons'
import BlocContact from 'components/molecules/BlocContact/BlocContact'
import Footer from 'components/molecules/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import StudioSections from 'components/organisms/StudioSections/StudioSections'
import { I18n } from "@lingui/react"

interface Props {}

const StudioPage: FunctionComponent<Props> = () => {
  const images = fetchStudioImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO
            title="Studio"
            description={i18n._("studio.metaDescription")}
          />
        )}
      </I18n>
      <Header />
      <StudioHero>
        <Container>
          <PrimaryTitle>
            <Trans id="studio.title">
              Oubliez vos projets pas vos idées, passez en mode startup !
            </Trans>
          </PrimaryTitle>
          <MainText>
            <Trans id="studio.description">
              Redfabriq vous accompagne dans votre digitalisation pour
              trasformer vos problématiques d’entreprise en produit et vous
              permettre d’accéder à de nouveaux relais de croissance.
            </Trans>
          </MainText>
          <StudioHeroScroll>
            <SmallText>
              <Trans id="studio.scroll">Scrollez pour commencer</Trans>
            </SmallText>
            <Icons name={IconsName.ARROW_LONG_DOWN} />
          </StudioHeroScroll>
        </Container>
      </StudioHero>
      <StudioSections images={images} />
      <Container>
        <BlocContact background={BlocFooter} withBirds />
      </Container>
      <Footer />
    </Layout>
  )
}

export default StudioPage
