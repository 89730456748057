import styled from 'styled-components'
import { ButtonRed, PrimaryTitle } from 'styles/Global'

export const BlocContactBg = styled.div`
  padding: 7rem 6.5rem 5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 7rem 4rem 4rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 4.5rem 2.75rem 4.75rem;
  }
`

export const BlocContactContainer = styled.div<{ background?: string }>`
  background: ${({ theme }) => theme.Colors.mainBlack};
  color: ${({ theme }) => theme.Colors.white};
  margin-bottom: 9rem;
  margin-top: ${({ background }) => (background ? 0 : '9.5rem')};
  position: relative;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
    max-width: 60%;
  }
  ${ButtonRed} {
    margin-bottom: 2rem;
  }
  ${BlocContactBg} {
    background: ${({ background }) =>
      background ? `url("${background}") no-repeat right bottom` : 'unset'};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0;
    margin-top: 11.75rem;
    margin-bottom: 3.5rem;
    ${PrimaryTitle} {
      max-width: 100%;
      margin-bottom: 2.5rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin-top: ${({ background }) => (background ? 0 : '11.75rem')};
    ${BlocContactBg} {
      background: none;
    }
  }
`

export const BlocContactImg = styled.img`
  position: absolute;
  bottom: -50px;
  right: 50px;
  width: auto;
  max-width: 400px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    right: -50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 300px;
    right: -100px;
    bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: -1;
    bottom: unset;
    top: -160px;
    right: -30px;
    transform: rotate(20deg);
  }
`

export const BlocContactBirds = styled.div`
  img {
    width: auto;
    position: absolute;
    &:first-child {
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
    }
    &:last-child {
      right: 10%;
      top: 15%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    img {
      width: 20%;
      &:first-child {
        left: 60%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    img {
      max-width: 80px;
      &:first-child {
        transform: none;
        top: -20px;
        bottom: unset;
        left: 20px;
      }
      &:last-child {
        top: -25%;
      }
    }
  }
`
