import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import {
  Col2,
  Container,
  MainText,
  NumberText,
  PrimaryTitle,
} from 'styles/Global'
import DrawPath from 'components/DrawPath'
import Image from 'components/Image'
import StudioPath from 'components/atoms/StudioPath/StudioPath'
import {
  Step1Img,
  Step2Img,
  Step2ImgExtra,
  Step3Img,
  Step3ImgBirds,
  Step5Img,
  StepBird,
  StudioRow,
  StudioSection,
} from './StudioSections.style'

interface Props {
  images: any
}

const StudioSections: FunctionComponent<Props> = ({ images }) => {
  const { stepsImg, bird1, bird2 } = images

  return (
    <>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow>
              <Col2>
                <NumberText>1.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row1.title">Idéation</Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row1.text">
                    Nous challengeons l’idée en comprenant le contexte
                    utilisateur et marché pour établir un concept technique et
                    business réalisable.
                  </Trans>
                </MainText>
              </Col2>
              <Step1Img>
                <Image fluid={stepsImg.step1.fluid} alt={stepsImg.step1.alt} />
              </Step1Img>
            </StudioRow>
            <StudioPath variant={1} />
          </Container>
        </DrawPath>
      </StudioSection>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow isReverse>
              <Col2>
                <NumberText>2.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row2.title">
                    Évaluation de l’opportunité
                  </Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row2.text">
                    Nos équipes product et finance ébauchent le business plan et
                    business model pour déterminer et sécuriser les
                    opportunités.
                  </Trans>
                </MainText>
              </Col2>
              <Step2Img>
                <Image fluid={stepsImg.step2.fluid} alt={stepsImg.step2.alt} />
                <Step2ImgExtra>
                  <Image
                    fluid={stepsImg.step2Extra.fluid}
                    alt={stepsImg.step2Extra.alt}
                  />
                </Step2ImgExtra>
              </Step2Img>
            </StudioRow>
            <StudioPath variant={2} />
          </Container>
        </DrawPath>
      </StudioSection>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow>
              <Col2>
                <NumberText>3.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row3.title">Prototypage et tests</Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row3.text">
                    Nos équipes design, tech et produit collaborent pour
                    réaliser un prototype testé en environnement réel.
                  </Trans>
                </MainText>
              </Col2>
              <Step3Img>
                <Image fluid={stepsImg.step3.fluid} alt={stepsImg.step3.alt} />
                <Step3ImgBirds>
                  <StepBird className="rellax" data-rellax-speed="2">
                    <Image
                      fixed={bird1.fixed}
                      alt={bird1.alt}
                      style={{
                        transform: 'rotate(20deg)',
                      }}
                    />
                  </StepBird>
                  <StepBird className="rellax" data-rellax-speed="4">
                    <Image
                      fixed={bird2.fixed}
                      alt={bird2.alt}
                      style={{
                        transform: 'rotate(-20deg)',
                      }}
                    />
                  </StepBird>
                </Step3ImgBirds>
              </Step3Img>
            </StudioRow>
            <StudioPath variant={3} />
          </Container>
        </DrawPath>
      </StudioSection>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow isReverse>
              <Col2>
                <NumberText>4.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row4.title">
                    Développement de la solution
                  </Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row4.text">
                    Nous capitalisons sur 15 années d’expérience en
                    développement de solution digitale pour créer un MVP qui
                    réponde au besoin du marché. L’automatisation de notre
                    développement technologique nous permet de déployer très
                    rapidement une solution.
                  </Trans>
                </MainText>
              </Col2>
            </StudioRow>
            <StudioPath variant={4} widthAuto />
          </Container>
        </DrawPath>
      </StudioSection>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow isReverse>
              <Col2>
                <NumberText>5.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row5.title">Commercialisation</Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row5.text">
                    Une fois la solution créée nos équipes marketing,
                    communication et commerciale mettent en place une stratégie
                    globale pour assurer la commercialisation du produit.
                  </Trans>
                </MainText>
              </Col2>
              <Step5Img>
                <Image fluid={stepsImg.step5.fluid} alt={stepsImg.step5.alt} />
              </Step5Img>
            </StudioRow>
            <StudioPath variant={5} />
          </Container>
        </DrawPath>
      </StudioSection>
      <StudioSection>
        <DrawPath>
          <Container>
            <StudioRow>
              <Col2>
                <NumberText>6.</NumberText>
                <PrimaryTitle as="h2">
                  <Trans id="studio.row6.title">Croissance</Trans>
                </PrimaryTitle>
                <MainText>
                  <Trans id="studio.row6.text">
                    La commercialisation de la solution nous permet de
                    structurer une startup avec un produit, une organisation,
                    une équipe et un revenu pour être scalable et lever des
                    fonds.
                  </Trans>
                </MainText>
              </Col2>
            </StudioRow>
            <StudioPath variant={6} widthAuto />
          </Container>
        </DrawPath>
      </StudioSection>
    </>
  )
}

export default StudioSections
