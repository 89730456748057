import React, {
  FunctionComponent,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { motion } from 'framer-motion'
import { StudioLine, StudioSvg } from './StudioPath.style'
import { PathContext } from 'components/DrawPath'
import { studioPathVariants } from 'components/atoms/StudioPath/StudioPath.data'

interface Props {
  variant: number
  widthAuto?: boolean
}

const StudioPath: FunctionComponent<Props> = ({ variant, widthAuto }) => {
  const { pathLength } = useContext(PathContext)
  const [windowWidth, setWindowWidth] = useState<number>(1440)
  const Line = studioPathVariants[variant]
  const getSvg = (key: string) => {
    return windowWidth < 767 && Line.mobile ? Line.mobile[key] : Line[key]
  }

  useLayoutEffect(() => {
    const eventResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', eventResize)

    setWindowWidth(window.innerWidth)

    return () => window.removeEventListener('resize', eventResize)
  }, [])

  return (
    <StudioLine widthAuto={widthAuto}>
      <StudioSvg
        width={getSvg('width')}
        height={getSvg('height')}
        viewBox={`0 0 ${getSvg('width')} ${getSvg('height')}`}
        svgPath={variant}
      >
        <defs>
          <mask id={`mask-${variant}`}>
            <motion.path
              d={getSvg('path')}
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="12"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="1 30"
              initial={{
                pathLength: 0,
              }}
              style={{
                pathLength,
              }}
            />
          </mask>
        </defs>
        <g mask={`url(#mask-${variant})`}>
          <motion.path
            d={getSvg('path')}
            fill="none"
            stroke="#1F1C2D"
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="1 30"
          />
        </g>
      </StudioSvg>
    </StudioLine>
  )
}

export default StudioPath
