import { graphql, useStaticQuery } from 'gatsby'
import { getFixedImage, getFluidImage } from 'helpers/getFluidImage'

export const fetchStudioImg = () => {
  const { allFile, bird1, bird2 } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "studio/stepsImg" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      bird1: file(relativePath: { eq: "studio/birds-1.png" }) {
        base
        childImageSharp {
          fixed(width: 85, height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bird2: file(relativePath: { eq: "studio/birds-2.png" }) {
        base
        childImageSharp {
          fixed(width: 134, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return {
    stepsImg: getFluidImage(allFile),
    bird1: getFixedImage(bird1),
    bird2: getFixedImage(bird2),
  }
}
