import styled from 'styled-components'
import { Col2, PrimaryTitle, Row } from 'styles/Global'
import { StudioLine } from 'components/atoms/StudioPath/StudioPath.style'

export const StudioSection = styled.section`
  &:nth-of-type(2) {
    padding: 9rem 0 0;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    overflow: hidden;
    &:nth-of-type(2) {
      padding: 6rem 0 0;
    }
    &:not(:nth-of-type(n + 6)) {
      padding: 6rem 0 0;
      ${StudioLine} {
        display: none;
      }
    }
    &:nth-of-type(6) {
      ${Col2}:first-child {
          order: 2;
        }
      }
    }
  }
`

export const StudioRow = styled(Row)`
  padding: 0;
  position: relative;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
  }
  & + ${StudioLine} svg {
    margin: ${({ isReverse }) => (isReverse ? '0 0 0 auto' : '0 auto 0 0')};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${PrimaryTitle} {
      margin-bottom: 0.5rem;
    }
  }
`

export const Step1Img = styled(Col2)`
  position: absolute;
  right: 50px;
  bottom: -100px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    right: 0;
    bottom: -200px;
    width: 40%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    right: 0;
    bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: static;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    transform: scale(1.2);
    margin: 2rem 0;
  }
`

export const Step2Img = styled(Col2)`
  position: absolute;
  left: 50px;
  bottom: 0;
  width: 25%;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 450px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: relative;
    left: 0;
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 70%;
  }
`

export const Step2ImgExtra = styled.div`
  position: absolute;
  top: -20px;
  right: 20px;
  width: 60px;
  height: 60px;
  animation: pulse 3s infinite linear;

  @keyframes pulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: 0;
    right: 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    width: 40px;
    height: 40px;
    right: 20px;
  }
`

export const Step3Img = styled(Col2)`
  position: absolute;
  bottom: -300px;
  width: 60%;
  right: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 40%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: static;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    transform: scale(1.7);
    margin: 2rem 0 0;
  }
`

export const StepBird = styled.div`
  position: absolute;
  top: 100px;
  right: 100px;
`

export const Step3ImgBirds = styled.div`
  ${StepBird} {
    &:first-of-type {
      top: 100px;
      right: unset;
      left: 100px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`

export const Step5Img = styled(Col2)`
  width: 40%;
  position: absolute;
  left: 10%;
  bottom: -200px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    left: 0;
    max-width: 650px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: static;
    order: 1;
    width: 60%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 80%;
    margin: 0 0 2rem;
  }
`
