import React, {
  createContext,
  FunctionComponent,
  Ref,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useSpring, useTransform, useViewportScroll } from 'framer-motion'

interface Props {}

export const PathContext = createContext<any>(null)

const DrawPath: FunctionComponent<Props> = ({ children }) => {
  const ref: Ref<any> = useRef(null)
  const [scrollStart, setScrollStart] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(0)
  const { scrollYProgress } = useViewportScroll()
  const y = useTransform(scrollYProgress, [scrollStart, scrollEnd], [0, 1])
  const pathLength = useSpring(y, { stiffness: 400, damping: 90 })

  useLayoutEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const offsetStart = rect.top + scrollTop + 150
    const offsetTop = rect.top + scrollTop

    const offsetEnd = offsetTop + rect.height

    const elementScrollStart = offsetStart / document.body.clientHeight
    const elementScrollEnd = offsetEnd / document.body.clientHeight

    setScrollStart(elementScrollStart)
    setScrollEnd(elementScrollEnd)
  })

  return (
    <PathContext.Provider
      value={{
        pathLength,
      }}
    >
      <div ref={ref}>{children}</div>
    </PathContext.Provider>
  )
}

export default DrawPath
