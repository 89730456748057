import styled from 'styled-components'

export const StudioLine = styled.div<{ widthAuto?: boolean }>`
  margin-top: 1rem;
  min-width: 200px;
  svg {
    width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
    min-height: 500px;
    fill: none;
  }
`

export const StudioSvg = styled.svg<{ svgPath: number }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    g path {
      stroke: ${({ svgPath }) => (svgPath === 6 ? 'none' : '#1F1C2D')};
    }
    path {
      fill: ${({ svgPath }) => (svgPath === 6 ? '#1F1C2D' : 'none')};
    }
  }
`
