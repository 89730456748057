import styled from 'styled-components'
import {
  Col2,
  Container,
  MainText,
  PrimaryTitle,
  Row,
  SmallText,
} from 'styles/Global'
import HeroBg from 'assets/images/studio/heroBg.png'
import HeroBgMobile from 'assets/images/studio/heroBgMobile.png'
import { StudioLine } from 'components/atoms/StudioPath/StudioPath.style'

export const StudioHero = styled.section`
  margin-top: 95px;
  text-align: center;
  min-height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: url('${HeroBg}') no-repeat center bottom;
  ${PrimaryTitle} {
    margin: 0 auto 1rem;
    max-width: 65%;
  }
  ${MainText} {
    max-width: 65%;
    margin: 0 auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${MainText}, ${PrimaryTitle} {
      max-width: 80%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url('${HeroBgMobile}');
    background-position: center -60px;
    min-height: unset;
    padding-top: 6rem;
    ${MainText}, ${PrimaryTitle} {
      max-width: 100%;
    }
  }
`

export const StudioContainer = styled(Container)`
  overflow: hidden;
`

export const StudioHeroScroll = styled.div`
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11.25rem;
  ${SmallText} {
    margin-bottom: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 6rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 16rem;
  }
`
